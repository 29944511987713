import React from "react";
import {useOverrides, Override} from "@quarkly/components";
import {Text, Box, Image, Section} from "@quarkly/widgets";

const defaultProps = {
    "padding": "80px 0 80px 0",
    "quarkly-title": "Reviews-3",
    "background": "--color-white",
    "display": "flex"
};
const overrides = {
    "text": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 70px 0px",
            "font": "normal 600 46px/1.2 --fontFamily-sans",
            "text-align": "center",
            "md-margin": "0px 0px 50px 0px",
            "children": "What users say about us"
        }
    },
    "box": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "grid",
            "grid-template-columns": "repeat(3, 1fr)",
            "grid-template-rows": "repeat(2, 01fr)",
            "lg-grid-template-columns": "repeat(2, 1fr)",
            "grid-gap": "36px 30px",
            "justify-content": "center",
            "lg-grid-template-rows": "auto",
            "sm-grid-template-columns": "1fr",
            "sm-grid-gap": "30px"
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "30px 30px 30px 30px",
            "background": "#f3f3f7",
            "border-radius": "8px",
            "box-shadow": "--m",
            "margin": "0px 0 0px 0px",
            "display": "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            "lg-margin": "0px 0 0 0px",
            "sm-padding": "20px 15px 20px 15px",
            "md-padding": "20px 20px 20px 20px"
        }
    },
    "box2": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "text1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 28px 0px",
            "font": "normal 300 17px/1.5 --fontFamily-sansHelvetica",
            "color": "--darkL1",
            "md-margin": "0px 0px 18px 0px",
            "children": "At first, I was really skeptical and quit right after the trial period without giving it a real chance. Then two weeks later, I lost 40% of my deposit blindly following some trader's strategy I thought I could trust. That's when I came back. After testing that same strategy here, I realized it was flawed from the start. If I hadn't been so stubborn and started using CDZV from the beginning, I could have saved my money. Now I never put real money into any strategy without testing it first!"
        }
    },
    "box3": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "align-items": "center",
            "md-min-width": 0,
            "md-min-height": 0
        }
    },
    "image": {
        "kind": "Image",
        "props": {
            "src": "https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/13q.jpg?v=2023-04-18T18:57:20.417Z",
            "display": "block",
            "margin": "0px 25px 0px 0px",
            "width": "80px",
            "height": "80px",
            "border-radius": "50%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "#b0b7bb",
            "object-fit": "cover",
            "md-margin": "0px 15px 0px 0px",
            "md-width": "68px",
            "md-height": "68px"
        }
    },
    "box4": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px"
        }
    },
    "text2": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 7px 0px",
            "font": "normal 600 20px/1.5 --fontFamily-sans",
            "children": "Adam Luis"
        }
    },
    "text3": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 400 16px/1.5 --fontFamily-sansHelvetica",
            "color": "#676d73",
            "children": "Trader"
        }
    },
    "box5": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "30px 30px 30px 30px",
            "background": "#f3f3f7",
            "border-radius": "8px",
            "box-shadow": "--m",
            "margin": "0px 0 0px 0px",
            "display": "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            "lg-margin": "0px 0 0 0px",
            "sm-padding": "20px 15px 20px 15px",
            "md-padding": "20px 20px 20px 20px"
        }
    },
    "box6": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "text4": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 28px 0px",
            "font": "normal 300 17px/1.5 --fontFamily-sansHelvetica",
            "color": "--darkL1",
            "md-margin": "0px 0px 18px 0px",
            "children": "I spent about 3 days getting familiar with the CDZV toolkit and used it to backtest my crypto trading strategies. Turns out only two of my original four strategies were actually worth keeping! But the cool thing is, I managed to develop three new ones that work even better. The best part though? I set up automated signals for entries and exits, which gives me so much more free time and prevents trading burnout. And hey, massive shoutout to the support team - they've got the patience of saints dealing with all my questions! 🤣"
        }
    },
    "box7": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "align-items": "center",
            "md-min-width": 0,
            "md-min-height": 0
        }
    },
    "image1": {
        "kind": "Image",
        "props": {
            "src": "https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/14q.jpg?v=2023-04-17T21:07:08.834Z",
            "display": "block",
            "margin": "0px 25px 0px 0px",
            "width": "80px",
            "height": "80px",
            "border-radius": "50%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "#b0b7bb",
            "object-fit": "cover",
            "md-margin": "0px 15px 0px 0px",
            "md-width": "68px",
            "md-height": "68px"
        }
    },
    "box8": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px"
        }
    },
    "text5": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 7px 0px",
            "font": "normal 600 20px/1.5 --fontFamily-sans",
            "children": "Wanessa Williamson"
        }
    },
    "text6": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 400 16px/1.5 --fontFamily-sansHelvetica",
            "color": "#676d73",
            "children": "Crypto trader"
        }
    },
    "box9": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "30px 30px 30px 30px",
            "background": "#f3f3f7",
            "border-radius": "8px",
            "box-shadow": "--m",
            "margin": "0px 0 0px 0px",
            "display": "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            "lg-margin": "0px 0 0 0px",
            "sm-padding": "20px 15px 20px 15px",
            "md-padding": "20px 20px 20px 20px"
        }
    },
    "box10": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "text7": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 28px 0px",
            "font": "normal 300 17px/1.5 --fontFamily-sansHelvetica",
            "color": "--darkL1",
            "md-margin": "0px 0px 18px 0px",
            "children": "Like many beginners, I've burned through my fair share of money following those 'get rich quick' trading courses and videos. Two months ago, I stumbled across a video on CDZV's YouTube channel that basically exposed all these fake trading gurus. Man, I wish I'd found them sooner! Now I'm hooked - I use their proven strategies and I'm slowly learning to develop my own. But the best part? I'm not throwing my money away anymore! Strategy Builder lets me test everything first, and honestly, it's shown me I'm not quite ready to trade my own strategies yet - and that's totally okay. These guys probably saved my trading account from disaster. Can't thank them enough!"
        }
    },
    "box11": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "align-items": "center",
            "md-min-width": 0,
            "md-min-height": 0
        }
    },
    "image2": {
        "kind": "Image",
        "props": {
            "src": "https://images.unsplash.com/photo-1590086782957-93c06ef21604?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000",
            "display": "block",
            "margin": "0px 25px 0px 0px",
            "width": "80px",
            "height": "80px",
            "border-radius": "50%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "#b0b7bb",
            "object-fit": "cover",
            "md-margin": "0px 15px 0px 0px",
            "md-width": "68px",
            "md-height": "68px"
        }
    },
    "box12": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px"
        }
    },
    "text8": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 7px 0px",
            "font": "normal 600 20px/1.5 --fontFamily-sans",
            "children": "Nikolas Adamson"
        }
    },
    "text9": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 400 16px/1.5 --fontFamily-sansHelvetica",
            "color": "#676d73",
            "children": "Futures trader"
        }
    },
    "box13": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "30px 30px 30px 30px",
            "background": "#f3f3f7",
            "border-radius": "8px",
            "box-shadow": "--m",
            "margin": "0px 0 0px 0px",
            "display": "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            "lg-margin": "0px 0 0 0px",
            "sm-padding": "20px 15px 20px 15px",
            "md-padding": "20px 20px 20px 20px"
        }
    },
    "box14": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "text10": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 28px 0px",
            "font": "normal 300 17px/1.5 --fontFamily-sansHelvetica",
            "color": "--darkL1",
            "md-margin": "0px 0px 18px 0px",
            "children": "After using this service for just 2 months, my trading results have nearly doubled - it's absolutely insane how much of a game-changer this tool is! These days, I won't even think about using a strategy without testing it first. And man, it's crazy how quick and easy the testing process is. Look, I'll keep this short - just give it a shot! Seriously, invest 2-3 days of your time into learning this, and you'll see your trading transform completely. Take it from someone who's been there - this stuff actually works!"
        }
    },
    "box15": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "align-items": "center",
            "md-min-width": 0,
            "md-min-height": 0
        }
    },
    "image3": {
        "kind": "Image",
        "props": {
            "src": "https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/5q.jpg?v=2023-04-17T21:12:16.116Z",
            "display": "block",
            "margin": "0px 25px 0px 0px",
            "width": "80px",
            "height": "80px",
            "border-radius": "50%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "#b0b7bb",
            "object-fit": "cover",
            "md-margin": "0px 15px 0px 0px",
            "md-width": "68px",
            "md-height": "68px"
        }
    },
    "box16": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px"
        }
    },
    "text11": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 7px 0px",
            "font": "normal 600 20px/1.5 --fontFamily-sans",
            "children": "Michael Haroldin"
        }
    },
    "text12": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 400 16px/1.5 --fontFamily-sansHelvetica",
            "color": "#676d73",
            "children": "Novice trader"
        }
    },
    "box17": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "30px 30px 30px 30px",
            "background": "#f3f3f7",
            "border-radius": "8px",
            "box-shadow": "--m",
            "margin": "0px 0 0px 0px",
            "display": "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            "lg-margin": "0px 0 0 0px",
            "sm-padding": "20px 15px 20px 15px",
            "md-padding": "20px 20px 20px 20px"
        }
    },
    "box18": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "text13": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 28px 0px",
            "font": "normal 300 17px/1.5 --fontFamily-sansHelvetica",
            "color": "--darkL1",
            "md-margin": "0px 0px 18px 0px",
            "children": "Having traded futures independently for over 7 years, I kept hitting the same wall - you need serious capital for serious returns. For three years straight, I tried getting into prop trading firms, but kept falling short. My strategy, while profitable enough to live on, was pretty unstable and forced me to take frequent breaks to manage risk. These inconsistent results kept blocking my path to prop trading.\n" +
                "Then a close trading buddy introduced me to CDZV. After going through their tutorials, I managed to code up my strategy by day three. What I saw next blew my mind - the backtests matched all my historical trades perfectly, showing every win and loss! In just one day of tweaking, I bumped my win rate from 47% to 73%. And guess what? I finally made it - I'm now trading with a prop firm! Never thought a single tool could be such a turning point in my career. Massive thanks to the team for creating this!"
        }
    },
    "box19": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "align-items": "center",
            "md-min-width": 0,
            "md-min-height": 0
        }
    },
    "image4": {
        "kind": "Image",
        "props": {
            "src": "https://images.unsplash.com/photo-1712857329031-eecb5980eec7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000",
            "display": "block",
            "margin": "0px 25px 0px 0px",
            "width": "80px",
            "height": "80px",
            "border-radius": "50%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "#b0b7bb",
            "object-fit": "cover",
            "md-margin": "0px 15px 0px 0px",
            "md-width": "68px",
            "md-height": "68px"
        }
    },
    "box20": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px"
        }
    },
    "text14": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 7px 0px",
            "font": "normal 600 20px/1.5 --fontFamily-sans",
            "children": "Tom Paulsen"
        }
    },
    "text15": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 400 16px/1.5 --fontFamily-sansHelvetica",
            "color": "#676d73",
            "children": "Prop trader"
        }
    },
    "box21": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "30px 30px 30px 30px",
            "background": "#f3f3f7",
            "border-radius": "8px",
            "box-shadow": "--m",
            "margin": "0px 0 0px 0px",
            "display": "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            "lg-margin": "0px 0 0 0px",
            "sm-padding": "20px 15px 20px 15px",
            "md-padding": "20px 20px 20px 20px"
        }
    },
    "box22": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "text16": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 28px 0px",
            "font": "normal 300 17px/1.5 --fontFamily-sansHelvetica",
            "color": "--darkL1",
            "md-margin": "0px 0px 18px 0px",
            "children": "I'll tell it like it is. It took me almost a week to learn the system. I started doing all the strategies from YouTube, I did not succeed, I was angry with myself and the whole world. But the guys from support had enough patience and fortitude to explain to me that CDZV is a simple and convenient tool. You just need to study it in order, not blinking from one thing to another. My friend started using CDZV already on the third day! He and I have tested more than 20 strategies and found four that have been bringing us money for several months! Guys from CDZV thank you very much for your patience and such a wonderful tool! I am shocked at how much money I would not have lost and how much I would have made during the time I was trading without you!"
        }
    },
    "box23": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "align-items": "center",
            "md-min-width": 0,
            "md-min-height": 0
        }
    },
    "image5": {
        "kind": "Image",
        "props": {
            "src": "https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/10q.jpg?v=2023-04-17T21:12:01.188Z",
            "display": "block",
            "margin": "0px 25px 0px 0px",
            "width": "80px",
            "height": "80px",
            "border-radius": "50%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "#b0b7bb",
            "object-fit": "cover",
            "md-margin": "0px 15px 0px 0px",
            "md-width": "68px",
            "md-height": "68px"
        }
    },
    "box24": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px"
        }
    },
    "text17": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 7px 0px",
            "font": "normal 600 20px/1.5 --fontFamily-sans",
            "children": "Maria Daminen"
        }
    },
    "text18": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 400 16px/1.5 --fontFamily-sansHelvetica",
            "color": "#676d73",
            "children": "Investor, trader"
        }
    }
};

const CdzvUsers = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Override slot="SectionContent"/>
        <Text {...override("text")} />
        <Box {...override("box")}>
            <Box {...override("box1")}>
                <Box {...override("box2")}>
                    <Text {...override("text1")} />
                </Box>
                <Box {...override("box3")}>
                    {/*<Image {...override("image")} />*/}
                    <Box {...override("box4")}>
                        <Text {...override("text2")} />
                        <Text {...override("text3")} />
                    </Box>
                </Box>
            </Box>
            <Box {...override("box5")}>
                <Box {...override("box6")}>
                    <Text {...override("text4")} />
                </Box>
                <Box {...override("box7")}>
                    {/*<Image {...override("image1")} />*/}
                    <Box {...override("box8")}>
                        <Text {...override("text5")} />
                        <Text {...override("text6")} />
                    </Box>
                </Box>
            </Box>
            <Box {...override("box9")}>
                <Box {...override("box10")}>
                    <Text {...override("text7")} />
                </Box>
                <Box {...override("box11")}>
                    {/*<Image {...override("image2")} />*/}
                    <Box {...override("box12")}>
                        <Text {...override("text8")} />
                        <Text {...override("text9")} />
                    </Box>
                </Box>
            </Box>
            <Box {...override("box13")}>
                <Box {...override("box14")}>
                    <Text {...override("text10")} />
                </Box>
                <Box {...override("box15")}>
                    {/*<Image {...override("image3")} />*/}
                    <Box {...override("box16")}>
                        <Text {...override("text11")} />
                        <Text {...override("text12")} />
                    </Box>
                </Box>
            </Box>
            <Box {...override("box17")}>
                <Box {...override("box18")}>
                    <Text {...override("text13")} />
                </Box>
                <Box {...override("box19")}>
                    {/*<Image {...override("image4")} />*/}
                    <Box {...override("box20")}>
                        <Text {...override("text14")} />
                        <Text {...override("text15")} />
                    </Box>
                </Box>
            </Box>
            <Box {...override("box21")}>
                <Box {...override("box22")}>
                    <Text {...override("text16")} />
                </Box>
                <Box {...override("box23")}>
                    {/*<Image {...override("image5")} />*/}
                    <Box {...override("box24")}>
                        <Text {...override("text17")} />
                        <Text {...override("text18")} />
                    </Box>
                </Box>
            </Box>
        </Box>
        {children}
    </Section>;
};

Object.assign(CdzvUsers, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvUsers;