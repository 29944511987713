export const priceMonthly = [
    {
        package_id: -2,
        name: 'FREE',
        price: '0,0',
        discount: '0',
        save_user: '1',
        url: 'https://panel.cdzv.com/login',
        crypto_url: ''
    },
    {
        package_id: 1,
        name: '1 MONTHS',
        price: '99,99',
        discount: '0',
        save_user: '0',
        url: 'https://buy.stripe.com/4gwaGG2KTfia55C9AW',
        crypto_url: ''
    },
    {
        package_id: 4,
        name: '12 MONTHS',
        price: '669,99',
        discount: '$140',
        save_user: '0',
        url: 'https://buy.stripe.com/fZe2aabhp2vofKg5kH',
        crypto_url: ''
    },
];

export const priceAnnually = [
    // {
    //     package_id: 6,
    //     name: '1 DAY',
    //     price: '9,99',
    //     url: 'https://buy.stripe.com/28o4ii1GPfia7dKeV7',
    //     crypto_url: 'https://www.coinpayments.net/index.php?cmd=_pay&reset=1&merchant=2b02c520ca42a4913f9e94d9534de3da&item_name=CDZV+1+DAY&currency=USD&amountf=9.99000000&quantity=1&allow_quantity=1&want_shipping=0&allow_extra=1&success_url=https%3A%2F%2Fcdzv.com%2F%3Fpay%3Dsxiniosax%26amp%3Bpid%3DCDZV_1D_TRADER'
    // },
    // {
    //     package_id: 4,
    //     name: 'INVESTOR 1Y',
    //     price: '44,99', // 45% discount
    //     url: 'https://buy.stripe.com/6oE2aa4T1fia7dK6oz',
    //     crypto_url: 'https://www.coinpayments.net/index.php?cmd=_pay&reset=1&merchant=2b02c520ca42a4913f9e94d9534de3da&item_name=CDZV+INVESTOR+1+YEAR&currency=USD&amountf=539.88000000&quantity=1&allow_quantity=1&want_shipping=0&allow_extra=1&success_url=https%3A%2F%2Fcdzv.com%2F%3Fpay%3Dsxiniosax%26amp%3Bpid%3DCDZV_1Y_INVESTOR'
    // },
    // {
    //     package_id: 5,
    //     name: 'TRADER 1Y',
    //     price: '69,99', // 50% discount
    //     url: 'https://buy.stripe.com/3cs9CC2KTfiaeGc9AM',
    //     crypto_url: 'https://www.coinpayments.net/index.php?cmd=_pay&reset=1&merchant=2b02c520ca42a4913f9e94d9534de3da&item_name=CDZV+TRADER+1+YEAR&currency=USD&amountf=839.88000000&quantity=1&allow_quantity=1&want_shipping=0&allow_extra=1&success_url=https%3A%2F%2Fcdzv.com%2F%3Fpay%3Dsxiniosax%26amp%3Bpid%3DCDZV_1Y_TRADER'
    // },
];