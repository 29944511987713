import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, Section } from "@quarkly/widgets";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"lg-padding": "60px 0 60px 0",
	"sm-padding": "30px 0 30px 0",
	"background": "--color-white",
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 40px 0px",
			"md-margin": "0px 0px 40px 0px",
			"lg-margin": "0px 0px 32px 0px",
			"display": "flex",
			"flex-direction": "row",
			"align-items": "center",
			"sm-margin": "0px 0px 10px 0px",
			"justify-content": "space-between",
			"lg-align-items": "center",
			"lg-flex-direction": "column",
			"lg-justify-content": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 32px 0px 0px",
			"font": "--headline1",
			// "color": "#ffffff",
			"text-align": "center",
			"md-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"lg-margin": "0px 0px 12px 0px",
			"flex": "0 0 auto",
			"children": "Comparison"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--lead",
			// "color": "--lightD2",
			"text-align": "left",
			"width": "50%",
			"md-width": "100%",
			"lg-width": "100%",
			"lg-text-align": "center",
			"children": "Comparison of CDZV Toolkit functions of different versions"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "32px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 0px 0px",
			"flex-wrap": "wrap",
			"width": "100%",
			"padding": "100px 0px 64px 0px",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"md-padding": "92px 0px 64px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "STRATEGY BUILDING"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "GRID STRATEGY BUILDING"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "BACK TESTING"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "AUTOMATION"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "RISK MANAGEMENT"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "STATE MACHINE"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "SESSIONS AND TIMES"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "PYRAMIDING"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 35px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "MONTHLY REPORTS"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 0px 0px",
			"md-font": "normal 500 16px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "LIQUIDATION TEST"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"sm-padding": "15px 4px 15px 4px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-wrap": "wrap",
			"width": "100%",
			"background": "#ecf2f7",
			"border-width": "1px",
			// "border-style": "solid",
			"border-radius": "16px",
			"padding": "32px 0px 64px 0px",
			"flex-direction": "column",
			"align-items": "center",
			"height": "100%"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 0px 0px",
			"lg-text-align": "center",
			"lg-font": "normal 600 20px/1.2 \"Source Sans Pro\", sans-serif",
			"md-font": "normal 500 12px/1.2 \"Source Sans Pro\", sans-serif",
			"height": "72px",
			"lg-height": "64px",
			"children": "Free Version"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-primary",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-primary",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-primary",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-primary",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon4": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdClose,
			"width": "40px",
			"height": "40px",
			"background": "--color-red",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon5": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdClose,
			"width": "40px",
			"height": "40px",
			"background": "--color-red",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "--color-green",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon6": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px"
		}
	},
	"icon7": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdClose,
			"width": "40px",
			"height": "40px",
			"background": "--color-red",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px"
		}
	},
	"icon8": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdClose,
			"width": "40px",
			"height": "40px",
			"background": "--color-red",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px"
		}
	},
	"icon9": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdClose,
			"width": "40px",
			"height": "40px",
			"background": "--color-red",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box16": {
		"kind": "Box",
		"props": {
			"sm-padding": "15px 4px 15px 4px"
		}
	},
	"box17": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-wrap": "wrap",
			"width": "100%",
			"background": "#ecf2f7",
			"border-width": "1px",
			// "border-style": "solid",
			"border-radius": "16px",
			"padding": "32px 0px 64px 0px",
			"flex-direction": "column",
			"align-items": "center",
			"height": "100%"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			// "color": "--light",
			"font": "--headline3",
			"margin": "0px 0px 0px 0px",
			"lg-text-align": "center",
			"lg-font": "normal 600 20px/1.2 \"Source Sans Pro\", sans-serif",
			"md-font": "normal 500 12px/1.2 \"Source Sans Pro\", sans-serif",
			"height": "72px",
			"lg-height": "64px",
			"children": "Pro Version"
		}
	},
	"box18": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon10": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box19": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon11": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box20": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "--color-darkL1",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon12": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px",
			"size": "30px",
			"border-radius": "100%",
			"background": "--color-green",
			"opacity": "1"
		}
	},
	"box21": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "--color-darkL1",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon13": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px",
			"size": "30px",
			"background": "--color-green",
			"border-radius": "100%",
			"opacity": "1"
		}
	},
	"box22": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "--color-darkL1",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon14": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px",
			"size": "30px",
			"background": "--color-green",
			"opacity": "1",
			"border-radius": "100%"
		}
	},
	"box23": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon15": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box24": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 14px 0px"
		}
	},
	"icon16": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box25": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px"
		}
	},
	"icon17": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box26": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 28px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px"
		}
	},
	"icon18": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	},
	"box27": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"background": "rgba(247, 251, 255, 0.15)",
			"border-radius": "100%",
			"height": "40px"
		}
	},
	"icon19": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdCheckmark,
			"width": "40px",
			"height": "40px",
			"background": "--color-green",
			"border-radius": "100%",
			"color": "#F7FBFF",
			"margin": "0px 0px 28px 0px"
		}
	}
};

const CdzvComparison = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Text {...override("text2")} />
					<Text {...override("text3")} />
					<Text {...override("text4")} />
					<Text {...override("text5")} />
					<Text {...override("text6")} />
					<Text {...override("text7")} />
					<Text {...override("text8")} />
					<Text {...override("text9")} />
					<Text {...override("text10")} />
					<Text {...override("text11")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Text {...override("text12")} />
					<Box {...override("box6")}>
						<Icon {...override("icon")} />
					</Box>
					<Box {...override("box7")}>
						<Icon {...override("icon1")} />
					</Box>
					<Box {...override("box8")}>
						<Icon {...override("icon2")} />
					</Box>
					<Box {...override("box9")}>
						<Icon {...override("icon3")} />
					</Box>
					<Box {...override("box10")}>
						<Icon {...override("icon4")} />
					</Box>
					<Box {...override("box11")}>
						<Icon {...override("icon5")} />
					</Box>
					<Box {...override("box12")}>
						<Icon {...override("icon6")} />
					</Box>
					<Box {...override("box13")}>
						<Icon {...override("icon7")} />
					</Box>
					<Box {...override("box14")}>
						<Icon {...override("icon8")} />
					</Box>
					<Box {...override("box15")}>
						<Icon {...override("icon9")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box16")}>
				<Box {...override("box17")}>
					<Text {...override("text13")} />
					<Box {...override("box18")}>
						<Icon {...override("icon10")} />
					</Box>
					<Box {...override("box19")}>
						<Icon {...override("icon11")} />
					</Box>
					<Box {...override("box20")}>
						<Icon {...override("icon12")} />
					</Box>
					<Box {...override("box21")}>
						<Icon {...override("icon13")} />
					</Box>
					<Box {...override("box22")}>
						<Icon {...override("icon14")} />
					</Box>
					<Box {...override("box23")}>
						<Icon {...override("icon15")} />
					</Box>
					<Box {...override("box24")}>
						<Icon {...override("icon16")} />
					</Box>
					<Box {...override("box25")}>
						<Icon {...override("icon17")} />
					</Box>
					<Box {...override("box26")}>
						<Icon {...override("icon18")} />
					</Box>
					<Box {...override("box27")}>
						<Icon {...override("icon19")} />
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvComparison, { ...Section,
	defaultProps,
	overrides
});
export default CdzvComparison;