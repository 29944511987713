import React from "react";
import {useOverrides} from "@quarkly/components";
import {Image, Box, Text, Section} from "@quarkly/widgets";
import MStrategyCarousel from "./MStrategyCarusel/MStrategyCarousel";

const defaultProps = {
    "md-padding": "70px 0 80px 0",
    "quarkly-title": "Product-2"
};
const overrides = {
    "box": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "grid",
            "grid-template-columns": "repeat(2, 1fr)",
            "grid-template-rows": "auto",
            "md-grid-template-columns": "1fr"
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            // "background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/NQ1!%20:%20!SMART_MONEY_OB_SWING_NQ_TF1h%20pub%202024-05-17%2014-47-37.png?v=2024-06-03T15:02:06.489Z) 0 0/cover no-repeat scroll padding-box",
            "padding": "0px 50px 0px 50px",
            "md-margin": "0px 0px 50px 0px",
            "md-padding": "50px 50px 50px 50px"
        }
    },
    "image": {
        "kind": "Image",
        "props": {
            "src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/png.png?v=2024-10-18T13:02:17.022Z",
            "display": "block",
            "max-width": "100%",
            "width": "400px"
        }
    },
    "box2": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "flex-direction": "column",
            "align-items": "flex-start",
            "justify-content": "center",
            "padding": "0px 40px 0px 40px",
            "lg-padding": "0px 0 0px 0"
        }
    },
    "text": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 40px 0px",
            "font": "normal 500 42px/1.2 --fontFamily-serifGaramond",
            "sm-margin": "0px 0px 30px 0px",
            "children": "190+ Ready-to-Use Trading Strategies"
        }
    },
    "text1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 50px 0px",
            "font": "--lead",
            "color": "#656b70",
            "children": "Access a suite of over 190 specialized strategies for cryptocurrency and stock trading. Our product unifies all strategies into a single, easy-to-manage module. Choose between manual signal-based trading or set up automated signal execution for exchange trading."
        }
    },
    "box3": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "lg-align-self": "center",
            "md-align-self": "auto",
            "sm-flex-direction": "column",
            "sm-align-items": "center",
            "sm-align-self": "center"
        }
    }
};

const CdzvStrategyPack = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Box {...override("box")}>
            <Box {...override("box1")}>
                <MStrategyCarousel/>
                {/*<Image {...override("image")} />*/}
            </Box>
            <Box {...override("box2")}>
                <Text {...override("text")} />
                <Text {...override("text1")} />
                <Box {...override("box3")} />
            </Box>
        </Box>
        <Image src={"https://cdzv.com/strategy_img/_WWWWW__CDZV_Members_Area_2024-11-06_20-21-46.png"}/>
        {/*<MStrategyCarusel/>*/}
        {children}
    </Section>;
};

Object.assign(CdzvStrategyPack, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvStrategyPack;